<template>
  <div style="padding-top: 150px">
    <div class="acea-row row-center clear_tit">
      <Button type="primary" @click="clearCache" class="l-m-r20">清除缓存</Button>
      <Button type="primary" @click="clearlog">清除日志</Button>
    </div>
  </div>
</template>

<script>

export default {
  name: 'index',
  mixins: [],
  data () {
    return {}
  },
  components: {},
  // 支柱道具属性
  props: {},
  // 计算属性 类似于 data 概念
  computed: {},
  // 监控 data 中的数据变化
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this 实例）
  created () {
  },
  // 生命周期 - 挂载完成（可以访问 DOM 元素）
  mounted () {
  },
  // 方法集合
  methods: {
    clearCache () {
      let delfromData = {
        title: '清除缓存',
        num: 0,
        url: 'system/refresh_cache/cache',
        method: 'get',
        ids: ''
      }
      this.$overallModal(delfromData).then((res) => {
        this.$Message.success(res.msg)
      }).catch((res) => {
        this.$Message.error(res.msg)
      })
    },
    clearlog () {
      let delfromData = {
        title: '清除日志',
        num: 0,
        url: 'system/refresh_cache/log',
        method: 'get',
        ids: ''
      }
      this.$overallModal(delfromData).then((res) => {
        this.$Message.success(res.msg)
      }).catch((res) => {
        this.$Message.error(res.msg)
      })
    }
  },
  // 生命周期 - 更新之前
  beforeUpdate () {
  },
  // 生命周期 - 更新之后
  updated () {
  },
  // 过滤器
  filters: {},
  // 生命周期 - 创建之前
  beforeCreate () {
  },
  // 生命周期 - 挂载之前
  beforeMount () {
  },
  // 生命周期 - 销毁之前
  beforeDestroy () {
  },
  // 生命周期 - 销毁完成
  destroyed () {
  },
  // 如果页面有 keep-alive 缓存功能,这个函数会触发
  // 进入的时候触发
  activated () {
  },
  // 离开的时候触发
  deactivated () {
  }
}
</script>

<style scoped>
</style>
